import React from 'react';
import { Button, Tabs } from 'antd';
import { uploads } from '../config/config';
const onChange = (key) => {
  
};


const tab7 = <Button  type="dark " ><a style={{color:'black'}} >Права и конфиденциальность</a> </Button>;
const Privacy=()=>{
    return(
        <>
         <div className='container'>
          <div className='row'>
          <Tabs>
         
         <Tabs.TabPane className='row' tab={tab7} key="7">
            **Политика конфиденциальности**

Настоящая политика конфиденциальности распространяется на приложение Birimdik (далее – «Приложение») для мобильных устройств, созданное компанией Birimdik_grop (далее – «Поставщик услуг») в качестве Бесплатной услуги. Данный сервис предназначен для использования «КАК ЕСТЬ».

**Сбор и использование информации**

Приложение собирает информацию, когда вы загружаете и используете его. Эта информация может включать в себя такую информацию, как

*   Адрес интернет-протокола вашего устройства (например, IP-адрес)
*   Страницы Приложения, которые вы посещаете, время и дата вашего посещения, время, проведенное на этих страницах
*   Время, затраченное на работу над Приложением
*   Операционная система, которую вы используете на своем мобильном устройстве

Приложение не собирает точную информацию о местоположении вашего мобильного устройства.

The Application collects your device's location, which helps the Service Provider determine your approximate geographical location and make use of in below ways:

*   Geolocation Services: The Service Provider utilizes location data to provide features such as personalized content, relevant recommendations, and location-based services.
*   Analytics and Improvements: Aggregated and anonymized location data helps the Service Provider to analyze user behavior, identify trends, and improve the overall performance and functionality of the Application.
*   Third-Party Services: Periodically, the Service Provider may transmit anonymized location data to external services. These services assist them in enhancing the Application and optimizing their offerings.

Поставщик услуг может использовать предоставленную вами информацию, чтобы время от времени связываться с вами и предоставлять вам важную информацию, необходимые уведомления и маркетинговые акции.

Для лучшего опыта при использовании Приложения Поставщик услуг может потребовать от вас предоставить нам определенную личную информацию, включая, помимо прочего, Birimdik.site@gmail.com. Информация, которую запрашивает Поставщик услуг, будет сохранена им и использована в соответствии с настоящей политикой конфиденциальности.

**Доступ третьих лиц**

Только агрегированные, обезличенные данные периодически передаются внешним службам, чтобы помочь Поставщику услуг в улучшении Приложения и его сервиса. Поставщик услуг может передавать вашу информацию третьим лицам способами, описанными в настоящем заявлении о конфиденциальности.

Обратите внимание, что Приложение использует сторонние сервисы, которые имеют собственную Политику конфиденциальности в отношении обработки данных. Ниже приведены ссылки на Политику конфиденциальности сторонних поставщиков услуг, используемых Приложением:

*   [Сервисы Google Play](https://www.google.com/policies/privacy/)
*   [Google Analytics для Firebase](https://firebase.google.com/support/privacy)

Поставщик услуг может разглашать предоставленную Пользователем и автоматически собранную информацию:

*   в соответствии с требованиями законодательства, например, для выполнения повестки в суд или аналогичного судебного процесса;
*   когда они добросовестно считают, что раскрытие информации необходимо для защиты их прав, защиты вашей безопасности или безопасности других лиц, расследования мошенничества или ответа на запрос правительства;
*   с их доверенными поставщиками услуг, которые работают от их имени, не имеют независимого использования информации, которую мы им раскрываем, и согласились придерживаться правил, изложенных в настоящем Заявлении о конфиденциальности.

**Право на отказ**

Вы можете легко прекратить сбор всей информации Приложением, удалив его. Вы можете использовать стандартные процессы удаления, которые могут быть доступны на вашем мобильном устройстве или через магазин мобильных приложений или сеть.

**Политика хранения данных**

Поставщик услуг будет хранить данные, предоставленные Пользователем, до тех пор, пока вы используете Приложение, и в течение разумного времени после этого. Если вы хотите, чтобы они удалили Предоставленные пользователем данные, которые вы предоставили через Приложение, свяжитесь с ними по адресу Birimdik.site@gmail.com, и они ответят в разумные сроки.

**Дети**

The Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13.

The Application does not address anyone under the age of 13. The Service Provider does not knowingly collect personally identifiable information from children under 13 years of age. In the case the Service Provider discover that a child under 13 has provided personal information, the Service Provider will immediately delete this from their servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact the Service Provider (Birimdik.site@gmail.com) so that they will be able to take the necessary actions.

**Security**

The Service Provider is concerned about safeguarding the confidentiality of your information. The Service Provider provides physical, electronic, and procedural safeguards to protect information the Service Provider processes and maintains.

**Changes**

This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.

This privacy policy is effective as of 2024-09-19

**Your Consent**

By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.

**Contact Us**

If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at Birimdik.site@gmail.com.

* * *

            </Tabs.TabPane>
        </Tabs>
          </div>
         </div>
       

    </>
    );
    }
    
    
    export default Privacy;

    